/* PALETTE */

:root {
  --black: #000000;
  --white: #ffffff;
  --red: #BA1A1A;
  --gray-20: #616161;
  --gray-40: #006E21;
  --gray-60: #e0e0e0;
  --gray-80: #f5f5f5;
  --green-10: #0F1F11;
  --green-20: #00390d;
  --green-40: #006e21;
  --green-95: #c8ffc2;
  --greenish-20: #002105;
  --greenish-40: #5a6057;
  --greenish-95: #eaf2ec;
  //
  --blue-40: #27397A;
  --blue-95: #EAF2F8;
  --blue-10: #0F0F1F;
  //
  --color-primary: var(--green-40);
  --color-text: var(--black);
  --color-text-inverted: var(--white);
  --color-text-light: var(--gray-20);
  --color-text-light-primary: var(--greenish-40);
  --color-background: var(--white);
  --color-background-light: var(--gray-80);
  --color-background-primary: var(--greenish-95);
  --color-background-inverted: var(--green-10);
  --color-text-placeholder: var(--gray-60);
  --color-text-disabled: var(--gray-20);
  --color-border-light: var(--gray-60);
}

/* VARIANTI COLORE - I love css vars */
.quellaltro {
  --color-primary: var(--blue-40);
  --color-text-light-primary: var(--blue-40);
  --color-background-primary: var(--blue-95);
  --color-background-inverted: var(--blue-10);
}

html[data-theme="mscb"] {
  --color-primary: var(--blue-40);
  --color-text-light-primary: var(--blue-40);
  --color-background-primary: var(--blue-95);
  --color-background-inverted: var(--blue-10);
  .quellaltro {
    --color-primary: var(--green-40);
    --color-text-light-primary: var(--greenish-40);
    --color-background-primary: var(--greenish-95);
    --color-background-inverted: var(--green-10);
  }
  
}