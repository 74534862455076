@use '../helpers' as *;

.page {
    &-main {
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
    }
    &-headings {
        @include container($bp-desktop, flex);
        @extend %basiccontent;
        flex-direction: column;
        gap: 3.2rem;
        text-align: center;
        align-items: center;
        padding-top: 4.8rem;
        @include breakpoint(tablet-landscape) {
            min-height: 24rem;
            justify-content: flex-end;
        }
    }
    &-title {
        @extend %font-primary-3xl;
    }
    &-excerpt {
        @extend %font-primary-m;
		max-width: var(--reading-width);
    }
    &-body {
        @include container($bp-desktop, flex);
        @extend %basiccontent;
        padding-top: 1.6rem;
        flex-direction: column;
        align-items: stretch;
        gap: 3.2rem;
    }
    &-content {
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
        align-items: center;
        > *:last-child {
            &:not([class*="banner"]) {
                margin-bottom: 3.2rem;
                @include breakpoint(tablet-landscape) {
                    margin-bottom: 6.4rem;
                }
            }
        }
        > * {
            width: 100%;
            max-width: $bp-desktop;
        }
    }
}
