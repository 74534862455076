@use '../helpers' as *;

.page-content .is-type-video {
    display: flex;
    border-radius: 4px;
    overflow: hidden;
	&.wp-embed-aspect-16-9 {
		aspect-ratio: 16 / 9;
	}
	&.wp-embed-aspect-4-3 {
		aspect-ratio: 4 / 3;
	}
	.wp-block-embed__wrapper,
	iframe {
		width: 100%;
		height: 100%;
	}
}