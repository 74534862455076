@use '../helpers' as *;

.wp-block-buttons {
	display: flex;
	flex-direction: row;
	gap: 1.2rem;

}
.wp-element-button {
	@extend %cta-base;
	.is-style-outline &,
	&.is-style-outline {
		@extend %cta-inverted;
	}
	.is-style-inline &,
	&.is-style-inline {
		@extend %cta-inline;
	}
}
