@use '../helpers' as *;

.cardlist.cardlist--focus {
	&:not([data-count="1"]) {
		width: 100vw;
		max-width: none;
		.cardlist-heading {
			max-width: var(--bp-desktop);	
			@extend %basiccontent;
		}
		.cardlist-inner {
			overflow: auto;	
			@extend %basiccontent;
			&-trail {
				gap: 1.2rem;
				display: grid;
				grid-template-rows: 1fr;
			}
			.card {
				width: 85vw;
			}
		}
		@for $i from 2 through 12 {
			&[data-count="#{$i}"] {
				.cardlist-inner {
					&-trail {
						grid-template-columns: repeat(#{$i}, 1fr);
					}
				}
			}
		}
	}	
}