@use '../helpers' as *;

.card.card--notice {
	@extend %font-primary-s;
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
	padding-top: 2.4rem;
	padding-bottom: 2.4rem;
	border-top: 1px solid var(--color-border-light);
	@include breakpoint(tablet-landscape) {
		display: grid;
		grid-template-columns: 1fr 2fr;
		grid-template-rows: repeat(2, auto);
		grid-template-areas: "meta title" "meta content";
		gap: 1.6rem;
		padding: 2.4rem;
		border: 1px solid var(--color-border-light);
		border-radius: var(--border-radius-m);
	}
	.card {
		&-meta {
			display: flex;
			flex-direction: column;
			line-height: 1.4;
			color: var(--color-text-light);
			>::before {
				display: none
			}
			time {
				display: flex;
				flex-direction: row;
				gap: 0.8rem;
			}
			@include breakpoint(tablet-landscape) {
				grid-area: meta;
			}
		}
		&-title {
			@extend %font-primary-m;
			@include breakpoint(tablet-landscape) {
				grid-area: title;
			}
		}
		&-content {
			display: flex;
			flex-direction: column;
			gap: 1.6rem;
			@include breakpoint(tablet-landscape) {
				grid-area: content;
			}
		}
	}
}