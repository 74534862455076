@use '../helpers' as *;

.header {
	&-navigation {
		&-toggle {
			@extend %cta-reset;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			&::before {
				content: "";
				@include icon('menu');
			}
			&[aria-expanded="true"] {
				&::before {
					@include icon('close');
				}
				+ .header-navigation-list {
					display: flex;
				}
			}
			@include breakpoint(tablet-landscape-lg) {
				display: none;
			}
		}
		&-list {
			display: none;
			flex-direction: column;
			position: absolute;
			top: var(--header-height);
			left: 0;
			right: 0;
			background-color: var(--color-background);
			width: 100%;
			height: calc((100 * var(--vh)) - var(--header-height));
			overflow-y: auto;
			padding: 4rem 1.6rem;
			align-items: flex-start;
			justify-content: center;
			gap: 4rem;
			list-style: none;
			@extend %font-header-menu;
			@include breakpoint(tablet-landscape-lg) {
				display: flex;
				flex-grow: 1;
				flex-shrink: 0;
				position: relative;
				width: auto;
				height: auto;
				padding: 0;
				top: auto;
				left: auto;
				right: auto;
				background-color: transparent;
				overflow-y: visible;
				flex-direction: row;
				align-items: center;
				justify-content: flex-end;
				gap: 2.4rem;
			}
			li {
				&[class*="highlight"] {
					@extend %cta-inverted;
				}
				@include breakpoint(tablet-landscape-lg, max) {
					&:first-child {
						margin-top: auto;
					}
					&[class*="highlight"] {
						width: 100%;
						margin-top: auto;
					}
					&:last-child:not([class*="highlight"]) {
						margin-bottom: auto;
					}
				}

			}
			a {
				text-decoration: none;
				&[aria-current] {
					color: var(--color-primary);
					text-decoration: underline;
				}
			}
		}
	}
}