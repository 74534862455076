@use '../helpers' as *;

.home {
    .page {
        &-headings {
            max-width: none;
            padding: 0;
            margin: 0;
            gap: 0;
            @include breakpoint(tablet-landscape) {
                display: grid;
                grid-template-columns: 1fr 2fr;
                grid-template-areas: "logo" "featured";
            }
            &-logo {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 0.8rem 3.2rem 4rem 3.2rem;
                @include breakpoint(tablet-landscape) {
                    padding: 0;
                }
                img {
                    width: auto;
                    max-height: 16rem;
                    @include breakpoint(tablet-landscape) {
                        max-height: 21.1rem;
                    }
                }
            }
        }
    }
}
