@use '../helpers' as *;

.navigator {
	&-wrapper {
		position: sticky;
		top: var(--header-height);
		z-index: zindex(pagenav);
		background-color: var(--color-background);
	}
	&--anchors {
		display: none;
		@include breakpoint(tablet-landscape) {
			display: block;
		}
	}
	&--accordion {
		display: block;
		@include breakpoint(tablet-landscape) {
			display: none;
		}
	}
	ul {
		position: relative;
		z-index: 1;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		column-gap: 2.4rem;
		padding: 0;
		list-style: none;
		li {
			button {
				@extend %cta-reset;
			}
			> a,
			> button {
				@extend %font-primary-s;
				text-decoration: none;
				color: inherit;
				white-space: nowrap;
				line-height: 4.8rem;
				&:hover {
					color: var(--color-primary);
				}
			}
			&.active {
				color: var(--color-primary);
				> a,
				> button {
					text-decoration: underline;
				}
			}
		}
	}
}