@use '../helpers' as *;

.page-template-page-landing {
    .page {
        &-headings {
            padding-top: 1.6rem;
            @include breakpoint(tablet-landscape) {
                min-height: auto;
                justify-content: flex-start;
            }
            &-logo {
                img {
                    width: auto;
                    height: 12rem;
                    @include breakpoint(tablet-landscape) {
                        height: 20rem;
                    }
                }
            }
        }
    }
    .footer {
        &-inner {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 1.6rem;
            a {
                @extend %font-primary-2xs-uppercase;
            }
        }
    }
}
