@use '../helpers' as *;

.single-post {
    .page {
        &-headings {
            text-align: left;
            max-width: $bp-desktop;
            align-items: flex-start;
            @include breakpoint(tablet-landscape) {
                padding-top: 16rem;
                display: grid;
                grid-template-columns: auto var(--reading-width);
                grid-template-rows: minmax(auto, 1fr);
                grid-template-areas: "meta title" "meta excerpt";
                column-gap: 0.8rem;
                row-gap: 3.2rem;
                justify-content: unset;
            }
        }
        &-title {
            @extend %font-secondary-xl;
            color: var(--color-primary);
            @include breakpoint(tablet-landscape) {
                grid-area: title;
            }
        }
        &-excerpt {
            @extend %font-primary-m;
            @include breakpoint(tablet-landscape) {
                grid-area: excerpt;
            }
        }
        &-meta {
            display: flex;
            flex-direction: column;
            gap: 0.6rem;
            @include breakpoint(tablet-landscape) {
                height: 100%;
            }
            a {
                text-decoration: none;
                &:hover {
                    color: var(--color-primary);
                    text-decoration: underline;
                }
            }
            .taglist {
                &--categories {
                    @extend %font-primary-s;
                    padding-bottom: 2.4rem;
                    @include breakpoint(tablet-landscape) {
                        padding-bottom: 0;
                        + * {
                            margin-top: auto;
                        }
                    }
                }
                &--writers {
                    @extend %font-primary-s;
                }
                &--cities {
                    @extend %font-primary-2xs-uppercase;
                    & + .taglist--date {
                        &::before {
                            content: '|';
                            line-height: 1;
                            padding-right: 0.6rem;
                            @include breakpoint(tablet-landscape) {
                                padding-right: 1rem;
                            }
                        }
                    }
                }
                &--date {
                    @extend %font-primary-2xs-uppercase;
                }
            }
            @include breakpoint(tablet-landscape) {
                grid-area: meta;
                padding-top: 4.2rem;
                gap: 1rem;
            }
        }
        &-content {
            @include breakpoint(tablet-landscape) {
                display: grid;
                grid-template-columns: auto var(--reading-width);
                column-gap: 0.8rem;
                row-gap: 3.2rem;
                width: 100%;
                > * {
                    grid-column-start: 2;
                }
            }
        }
    }
}
