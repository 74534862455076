@use '../helpers' as *;

.page-content,
.wp-block-post-content {
	.wp-block-columns {
		@extend %font-primary-xs;
		display: flex;
		flex-direction: column;
		gap: 1.6rem;
		@include breakpoint(tablet-landscape) {
			flex-direction: row;
			gap: 2.4rem;
			&:not[class*=".are-vertically-aligned"] {
				align-items: center;
			}
		}
		@include breakpoint(tablet-landscape, max) {
			&.is-not-stacked-on-mobile {
				flex-direction: row;
			}
		}
		.wp-block-column {
			display: flex;
			flex-direction: column;
			gap: 1.6rem;
			@include breakpoint(tablet-landscape) {
				gap: 2.4rem;
			}
		}
		&.highlight {
			max-width: var(--reading-width);
			padding: 2.4rem;
			border-radius: var(--border-radius-m);
			background-color: var(--color-background-primary);
		} 
	}
}