@use 'breakpoints' as *;

@mixin container($max-width, $display: block) {
  position: relative;
  display: $display;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: $max-width;
}

%basiccontent {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  @include breakpoint(tablet-landscape) {
      padding-left: 3.2rem;
      padding-right: 3.2rem;
  }
}