@use '../helpers' as *;

.page-content,
.wp-block-post-content {
	.wp-block-gallery {
		grid-column: 1 / -1;
		max-width: none;
	}
	.gallery {
		&-inner {
			display: grid;
			grid-template-columns: auto;
			grid-gap: 1.2rem;
			grid-template-columns: repeat(2, 1fr);
			@include breakpoint(tablet-landscape) {
				grid-template-columns: repeat(3, 1fr);
			}
		}
		&-thumb {
			@extend %cta-reset;
			border-radius: var(--border-radius-s);
			overflow: hidden;
			img {
				display: block;
				width: 100%;
				height: auto;
				object-fit: cover;
				aspect-ratio: 1/1;
				aspect-ratio: 4/3;
			}
		}
	}
}

.modal--gallery {
	.gallery-slides {
		&-inner {
			@include breakpoint(tablet-landscape, max) {
				display: flex;
				flex-direction: column;
				gap: 1.2rem;
			}
		}
		@include breakpoint(tablet-landscape) {
			display: block;
			figure {
				img {
					width: 100%;
					height: 100%;
					display: block;
					object-fit: contain;
				}
			}
		}
	}
}