@use '../helpers' as *;

.cardlist{
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
	&-heading {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		&-title {
			@extend %font-primary-m;
		}
		&-link {
			@extend %font-primary-s-medium;
			color: var(--color-primary);
			text-decoration: none;
			line-height: 3.6rem;
			display: flex;
			flex-direction: row;
			align-items: center;
			&::after {
				content: '';
				@include icon('chevron', var(--color-primary));
			}
		}
	}
	&-inner {
		display: grid;
		grid-template-columns: 1fr;
		gap: 2.4rem;
	}
	& + .cardlist {
		margin-top: 4rem;
		@include breakpoint(tablet-landscape) {
			margin-top: 6.4rem;
		}
	}
	&:last-child {
		margin-bottom: 4rem;
		@include breakpoint(tablet-landscape) {
			margin-bottom: 6.4rem;
		}
	}
}