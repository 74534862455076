@use 'breakpoints' as *;

/**
* NOTA BENE
* usare / per le divisioni è deprecato, utilizzare invece math.div($variable, 4); (dopo un @use "sass:math";)
* https://sass-lang.com/documentation/breaking-changes/slash-div
*/

:root {
	--root-fontsize: 10px;
    --root-lineheight: 1;
    --header-height-mobile: 5.6rem;
    --header-height-desktop: 7.2rem;
    --header-height: var(--header-height-mobile);
    --input-height: 3.6rem;
    --button-height: 3.6rem;
    --button-min-width: 2.4rem;
    --reading-width: 80rem; //80ch;
    --border-radius-m: 0.8rem;
    --border-radius-s: 0.4rem;
    --vh: 1vh; // override in JS
}

@include breakpoint(tablet-landscape) {
    :root {
        --header-height: var(--header-height-desktop);
    }
}