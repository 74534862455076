@use '../helpers' as *;

.card.card--featured {
	position: relative;
	overflow: hidden;
	aspect-ratio: 3/4;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	@include breakpoint(tablet-landscape) {
		aspect-ratio: 4/3;
	}
	.card {
		&-figure {
			position: relative;
			z-index: 1;
			grid-area: 1 / 1 / -1 / -1;
			&::before,
			&::after {
				position: absolute;
				content: '';
				z-index: 2;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
			&::after {
				background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.2) 100%);
			}
			img {
				position: relative;
				z-index: 1;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		&-content {
			position: relative;
			z-index: 2;
			color: var(--white);
			grid-area: 1 / 1 / -1 / -1;
			padding: 4rem 1.6rem;
			display: flex;
			flex-direction: column;
			text-align: left;
			margin-top: auto;
			align-items: flex-start;
			gap: 1.6rem;
			@include breakpoint(tablet-landscape) {
				padding-left: 3.2rem;
				padding-right: 3.2rem;
				display: grid;
				grid-template-columns: 1fr auto;
				grid-template-rows: repeat(3, auto);
				grid-template-areas: "location location" "title title" "meta cta";
				align-items: center;
			}
		}
		&-title {
			@extend %font-secondary-xl;
			@include breakpoint(tablet-landscape) {
				grid-area: title;	
			}
			+ .card-meta {
				@extend %font-primary-xs;
				@include breakpoint(tablet-landscape) {
					grid-area: meta;	
				}
			}
		}
		&-meta {
			@extend %font-primary-s;
			@include breakpoint(tablet-landscape) {
				grid-area: location;	
			}
		}
		&-cta {
			@extend %cta-base;
			@include breakpoint(tablet-landscape) {
				grid-area: cta;	
			}
		}
	}
}

