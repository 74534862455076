@use '../helpers' as *;



.card.card--default {
	border-top: 1px solid var(--color-border-light);
	display: flex;
	flex-direction: row-reverse;
	gap: 0.8rem;
	padding-top: 0.8rem;
	align-items: flex-start;
	justify-content: space-between;
	@include breakpoint(tablet-portrait) {
		gap: 1.6rem;
		flex-direction: column;
		justify-content: flex-start;
		padding-top: 0;
		border-top: 0 solid;
	}
	.card {
		&-figure {
			overflow: hidden;
			border-radius: var(--border-radius-s);
			width: 10rem;
			flex-shrink: 0;
			@include breakpoint(tablet-portrait) {
				width: 100%;
			}
			img {
				position: relative;
				z-index: 1;
				width: 100%;
				height: auto;
				object-fit: cover;
				aspect-ratio: 1/1;
				@include breakpoint(tablet-portrait) {
					aspect-ratio: 4/3;
				}
			}
		}
		&-content {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 0.8rem;
			@extend %font-primary-2xs;
			color: var(--color-text-light);	
			@include breakpoint(tablet-portrait) {
				height: 100%;
				min-height: 13rem;
			}
		}
		&-title {
			@extend %font-primary-m;
			color: var(--color-text);
		}
		&-excerpt {
			display: none;
			@include breakpoint(tablet-portrait) {
				display: block;
				margin-bottom: auto;
			}
		}
		&-meta:not(.card-meta--categories) {
			display: none;
			@include breakpoint(tablet-portrait) {
				display: flex;
			}
		}
		&-meta--categories {
			@extend %font-primary-2xs;
			color: var(--color-text-light);	
		}
		&-cta {
			@extend %cta-inline;
			@include breakpoint(tablet-portrait) {
				display: none;
			}
		}
	}
}