@use '../helpers' as *;

.page-template-newsletter-builder {
	.fscb_nl_hidden {
		display: none;
	}
	.page-content--nl-dashboard {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		max-width: 30rem;
		gap: 2.4rem;
		button {
			width: auto;
		}
		.form-group {
			flex-basis: 100%;
			textarea {
				font-family: monospace;
				font-size: 11px;
			}
		}
		@include breakpoint(tablet-landscape) {
			position: sticky;
			top: var(--header-height);
			z-index: zindex(header);
			background-color: var(--color-background);
			padding: 2.4rem;
			border-radius: var(--border-radius-m);
		}
	}
	.nl-controls {
		display: flex;
		flex-direction: column;
		gap: 1.6rem;
		textarea {
			font-family: monospace;
			font-size: 14px;
		}
	}
}