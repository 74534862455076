@use '../helpers' as *;

.accordion {
	background-color: var(--color-background);
	&-summary {
		@extend %font-primary-s;
		padding: 1.2rem 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		cursor: pointer;
		&::marker {
			content: '';
		}
		&::after {
			content: '';
			@include icon('chevron');
			transform: rotate(90deg);
			[open] & {
				transform: rotate(-90deg);
			}
		}
	}
	&-content {
		padding: 1.2rem 0.8rem;
		background-color: var(--color-background-light);
		border-radius: var(--border-radius-s);
		ul {
			list-style: none;
			display: flex;
			gap: 1.2rem;
			flex-direction: column;
			li {
				@extend %font-primary-s;
				button {
					@extend %cta-reset;
				}
				&.active {
					color: var(--color-primary);
					a,
					button {
						color: inherit;
					}
				}
				a,
				button {
					text-decoration: none;
				}
			}
		}
	}
}