@use '../helpers' as *;

%list-style {
	@extend %font-secondary-m;
	max-width: var(--reading-width);
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	li {
		position: relative;
		display: inline-block;
		.block-editor-rich-text__editable {
			display: inline-block;
		}
	}
}

.page-content,
.wp-block-post-content {
	> ul,
	div:not([class*="card"]) ul:not([class*="picker__variations"]) div:not([class*="navigator"]) {
		@extend %list-style;
		li {
			padding-left: 4.8rem;
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				content: "";
				@include icon('bullet', var(--color-primary));
				flex-shrink: 0;
				margin: 0.5rem 0 0 1.5rem;

			}
		}
	}
	> ol,
	div:not([class*="card"]) ol {
		@extend %list-style;
		li {
			padding-left: 4.8rem;
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				content: "N.";
				flex-shrink: 0;
				color: var(--color-primary);
				text-align: right;
				width: 5.4rem;
				padding-right: 1.5rem;
				line-height: 3rem;

			}
		}
		@for $i from 1 through 10 {
			li:nth-child(#{$i}) {
				&::before {
					content: "#{$i}.";
				}
			}
		}
	}
}