@use '../helpers' as *;

.visually-hidden {
	@extend %visually-hidden;
}

/* WP */

.has-block-align-center {
	align-items: center;
	justify-content: center;
}
.has-text-align-center {
	text-align: center;
}
.has-text-align-left {
	text-align: left;
}
.has-text-align-right {
	text-align: right;
}
.are-vertically-aligned-top {
	align-items: flex-start;
}
.are-vertically-aligned-center {
	align-items: center;
}
.are-vertically-aligned-bottom {
	align-items: flex-end;
}

.has-text-color {
	&[class*="has-primary"] {
		color: var(--color-primary);
	}
}