@use '../helpers' as *;

.page-content,
.wp-block-post-content {
	@include headings {
		font-family: var(--font-sansserif);
	}
	> h1,
	div:not([class*="card"]):not([class*="banner"]) h1 {
		@extend %font-primary-3xl;
		max-width: var(--reading-width);
	}
	> h2,
	div:not([class*="card"]):not([class*="banner"]) h2 {
		@extend %font-primary-l;
		max-width: var(--reading-width);
	}
	> h3,
	div:not([class*="card"]):not([class*="banner"]) h3 {
		@extend %font-primary-m;
		max-width: var(--reading-width);
	}
}