@use '../helpers' as *;

.cardlist.cardlist--post.cardlist--default {
	&.cardlist--related {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
	.cardlist {
		&-inner {
			@include breakpoint(tablet-portrait) {
				column-gap: 1.2rem;
				row-gap: 3.2rem;
				grid-template-columns: repeat(2, 1fr);
			}
			@include breakpoint(tablet-landscape) {
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}	
}