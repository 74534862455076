@use '../helpers' as *;

.page-filters {
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
	@include breakpoint(tablet-landscape) {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		&::before {
			content: '';
			min-width: 8rem;
		}
		.filter--search {
			order: 2;
		}
	}
	.filter--search {
		@include breakpoint(tablet-landscape, max) {
			font-size: 2.2rem;
			border-bottom: 1px solid;
			width: 100%;
			justify-content: space-between;
		}
	}
}

.filter--search {
	@extend %cta-inline;
	color: inherit;
	text-decoration: none;
	gap: 0.8rem;
	&::after {
		content: '';
		@include icon('search');
	}
	input {
		@extend %field-reset;
		@include breakpoint(tablet-landscape) {
			max-width: 8rem;
		}
		&:placeholder-shown {
			+ button[type="reset"] {
				display: none;
			}
		}
	}
	button[type="reset"] {
		@extend %cta-inline;
		&::after {
			content: '';
			@include icon('close');
		}
	}
}