@use 'sizes' as *;
@use 'typography' as *;


%field-reset {
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    font: inherit;
    color: inherit;
    line-height: inherit;
    text-align: inherit;
    text-decoration: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    cursor: inherit;
    outline: inherit;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
}

%cta-label {
	@extend %font-primary-2xs-uppercase;
    color: var(--color-text-light);
    :focus + & {
        color: var(--color-text);
    }
}
%cta-field {
	@extend %font-primary-s;
    position: relative;
    cursor: pointer;
    line-height: 1;
    display: inline-block;
	min-height: var(--button-height);
    padding: 0.6rem 1.2rem;
    border-radius: var(--border-radius-s);
    color: var(--color-text-light);
    background-color: var(--color-background);
    border: 1px solid currentColor;
	&:focus {
		color: var(--color-text);
		& + label {
			color: var(--color-text);
		}
	}
}