@use '../helpers' as *;

.wp-block-image {
	max-width: var(--reading-width);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	picture {
		width: 100%;
	}
	img {
		border-radius: var(--border-radius-m);
		width: 100%;
	}
	figcaption {
		@extend %font-primary-2xs;
	}
	&.is-style-full-width {
		grid-column: 1 / -1;
		max-width: none;
	}
}