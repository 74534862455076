/**
* Visually hidden element property
* to manage hidden elements that keep existing in the DOM
*/
%visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

%visually-unhidden {
    visibility: visible;
    width: auto;
    height: auto;
    clip: auto;
}