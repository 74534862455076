@use '../helpers' as *;

.form {	
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
	&-field {
		@extend %cta-field;	
	}
	&-label {
		@extend %cta-label;
	}
	&-group {
		display: flex;
		flex-direction: column-reverse;
		gap: 0.8rem;
		input,
		textarea,
		select {
			@extend %cta-field;
		}
		label {
			@extend %cta-label;
		}
		&--boolean {
			input[type="radio"],
			input[type="checkbox"] {
				min-height: auto;
			}
			label {
				letter-spacing: 1;
				text-transform: unset;
				display: flex;
				flex-direction: row;
				gap: 1.2rem;
			}
		}
	}
	&-cta {
		@extend %cta-base;
	}
	/*  */
	&--inline {
		@include breakpoint(tablet-portrait) {
			flex-direction: row;
			align-items: center;	
		}
		.form-field {
			border-radius: var(--button-height);
		}
	}
	&-feedback {
		display: flex;
		flex-direction: column;
		gap: 2.4rem;
		padding: 2.4rem;
		background-color: var(--color-background-primary);
		border-radius: var(--border-radius-m);
		> .form-feedback-message {
			@extend %cta-label;
		}
	}
}