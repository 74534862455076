@use '../helpers' as *;

.modal--search {
  .form--search {
    display: flex;
    flex-direction: column;
    @include breakpoint(tablet-landscape) {
      flex-direction: row;
      align-items: flex-end;
    }
    gap: 2.4rem;
    &-group {
      border: 0 solid;
      padding: 0;
      display: flex;
      flex-direction: column-reverse;
      gap: 2.4rem;
      width: 100%;
      input {
        width: 100%;
        border: 0 solid;
        border-radius: 0;
        @extend %font-primary-2xl;
        border-bottom: 1px solid var(--color-text-light);
        &::placeholder {
          color: var(--color-text-placeholder);
        }
        &:focus {
          box-shadow: none;
          border-color: var(--color-border-light);
        }
        @include breakpoint(tablet-landscape) {
          font-size: 5rem;
        }
      }
      label {
        @extend %font-primary-s;
      }
    }
    .button {
      @extend %cta-base;
    }
  }
}