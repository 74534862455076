@use '../helpers' as *;

.banner {
	background-color: var(--color-background-primary);
	width: 100vw;
	max-width: none;
	align-self: center;
	&-inner {
		display: grid;
		grid-template-columns: auto;
		padding: 2.4rem;
		gap: 3.2rem;
		@include breakpoint(tablet-landscape) {
			max-width: $bp-desktop;
			margin-left: auto;
			margin-right: auto;
			padding: 3.2rem;
			grid-template-columns: repeat(2, 1fr);
			.banner--donate & {
				grid-template-areas: "pretitle pretitle" "title copy" "title actions" "logo note"
			}
			.banner--newsletter & {
				grid-template-areas: "pretitle pretitle" "title title" "actions actions" "logo note"
			}
			.banner--nlform & {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
			}
		}
	}
	&-pretitle {
		@extend %font-primary-s-medium-uppercase;
		color: var(--color-primary);
		@include breakpoint(tablet-landscape) {
			grid-area: pretitle;
		}
	}
	&-title {
		@extend %font-primary-3xl;
		color: var(--color-primary);
		@include breakpoint(tablet-landscape) {
			grid-area: title;
			.banner--newsletter & {
				max-width: 20ch;
			}
		}
	}
	&-copy {
		@extend %font-primary-s;
		@include breakpoint(tablet-landscape) {
			margin-top: auto;
			grid-area: copy;
		}
	}
	&-actions {
		.banner--donate & {
			display: flex;
			flex-direction: row;
			gap: 2.4rem;
			flex-wrap: nowrap;
		}
		@include breakpoint(tablet-landscape) {
			grid-area: actions;
			margin-bottom: auto;
		}
	}
	&-cta {
		&:first-child {
			@extend %cta-base;
		}
		& + & {
			@extend %cta-inverted;
		}
	}
	&-logo {
		width: auto;
		height: auto;
		@include breakpoint(tablet-landscape) {
			grid-area: logo;
		}
		// TODO sistemare il logo
		a {
			width: auto;
			height: 100%;
		}
		img {
			width: auto;
			height: 6rem;
		}
	}
	&-note {
		@extend %font-primary-2xs;
		color: var(--color-text-light-primary);
		@include breakpoint(tablet-landscape) {
			grid-area: note;
			margin-top: auto;
		}
	}
}