@use '../helpers' as *;

main + .footer {
	display: flex;
	flex-direction: column;
	padding: 4rem 0;
	gap: 4rem;
	color: var(--color-text-inverted);
	background-color: var(--color-background-inverted);
	@include breakpoint(tablet-landscape) {	
		padding: 6.4rem 0;
	}
}

.footer {
	&-inner {
		@include container($bp-desktop, flex);
		flex-direction: column;
		gap: 2.4rem;
		padding-left: 1.6rem;
		padding-right: 1.6rem;
		&.footer-mainarea {
			@include breakpoint(tablet-landscape) {	
				flex-direction: row;
				justify-content: flex-start;
				gap: 6rem;
				padding-left: 3.2rem;
				padding-right: 3.2rem;
			}
		}
	}
	&-title {
		@extend %font-primary-s;
	}
	&-section {
		@extend %font-primary-2xs;
		line-height: 150%;
		display: flex;
		flex-direction: column;
		gap: 1.6rem;
		ul {
			list-style: none;
			a {
				text-decoration: none;
			}
		}
		&--newsletter {
			@include breakpoint(tablet-landscape) {	
				flex-basis: 25%;
				margin-left: auto
			}
			.footer-title {
				@extend %font-primary-2xl;
			}
		}
		&--logo {
			img {
				margin: 0 auto;
				filter: brightness(0) invert(1);
				height: 6rem;
				width: auto;
			}
		}
		&--languages {
			@extend %font-primary-2xs-uppercase;
			ul {
				display: flex;
				flex-direction: row;
				justify-content: center;
				gap: 1rem;
				span {
					color: var(--color-text-disabled);
				}
			}
		}
	}
	
}