@use '../helpers' as *;

.card {	
	&-title {
		a {
			text-decoration: none;
		}
	}
	&-meta {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		> * + * {
			&::before {
				display: inline-block;
				content: '-';
				padding-left: 1ch;
				padding-right: 1ch;
			}
		}
	}
}