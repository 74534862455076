@use '../helpers' as *;

.card.card--focus {
	position: relative;
	overflow: hidden;
	aspect-ratio: 9/16;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 100%;
	border-radius: var(--border-radius-m);
	@include breakpoint(tablet-landscape) {
		aspect-ratio: 16/9;
	}
	.card {
		&-figure {
			position: relative;
			z-index: 1;
			grid-area: 1 / 1 / -1 / -1;
			&::before,
			&::after {
				position: absolute;
				content: '';
				z-index: 2;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
			&::after {
				background: linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 75%);
			}
			img {
				position: relative;
				z-index: 1;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		&-content {
			position: relative;
			z-index: 2;
			color: var(--white);
			grid-area: 1 / 1 / -1 / -1;
			padding: 1.6rem;
			display: flex;
			flex-direction: column;
			text-align: left;
			margin-top: auto;
			align-items: flex-start;
			gap: 1.6rem;
			@include breakpoint(tablet-landscape) {
				padding: 3.2rem;
			}
		}
		&-title {
			@extend %font-primary-2xl;
		}
		&-excerpt {
			@extend %font-primary-s;
		}
		&-cta {
			@extend %cta-inline;
			color: inherit;
		}
	}
}

