@use '../helpers' as *;

.pagination {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 6.4rem 2.4rem;
	ul {
		list-style: none;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 2.4rem;
		li {
			a, 
			span {
				@extend %font-primary-s;
				line-height: var(--button-min-height);
				min-width: var(--button-min-height);
				color: var(--color-text-light);
				text-decoration: none;
				&.current {
					color: var(--color-primary);
					font-weight: 600;
				}
				&.prev {
					padding-right: 2.4rem;
					&::before {
						content: "";
						@include icon('arrow', var(--color-primary));
						transform: scaleX(-1);
					}
				}
				&.next {
					padding-left: 2.4rem;
					&::before {
						content: "";
						@include icon('arrow', var(--color-primary));
					}
				}
			}
		}
	}
}