@use '../helpers' as *;

.post-password-form {
	@extend %font-secondary-m;
	max-width: var(--reading-width);
	display: flex;
    flex-direction: column;
    gap: 1rem;
	label {
		@extend %cta-label;
		display: flex;
		flex-direction: row;
		align-items: center;
		@include breakpoint(tablet-portrait) {
			display: inline-block;
		}
	}
	input[type="password"] {
		@extend %cta-field;
		flex-basis: 100%;
		margin-left: 1rem;
		@include breakpoint(tablet-portrait) {
			margin-right: 1rem;
		}
	}
	input[type="submit"] {
		@extend %cta-base;
		margin: 1rem 0 0 auto;
		@include breakpoint(tablet-portrait) {
			margin: 0;
			display: inline-block;
		}
	}
}