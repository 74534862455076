@use '../helpers' as *;

code,
pre {
	display: flex;
	margin: 2.4rem 0;
	padding: 1.6rem;
	font-size: 1.2rem;
	color: var(--color-primary);
	background-color: var(--color-background-primary);
	border-radius: var(--border-radius-s);
}