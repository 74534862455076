@use '../helpers' as *;

.modal {
  position: fixed;
  z-index: zindex(modals);
  &-overlay {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }
  &.is-open {
    > .modal-overlay {
      display: flex;
    }
  }
  &-dialog {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90vw;
    height: 90vh;
    margin: 0;
    background-color: var(--color-background);
    color: var(--color-text);
    cursor: auto;
    @include breakpoint(tablet-portrait) {
      max-width: calc(var(--reading-width) * 0.75);
      max-height: calc(var(--reading-width) * 2);
      height: auto;
    }
    .modal--full & {
      width: 100vw;
      height: calc(var(--vh) * 100);
      @include breakpoint(tablet-portrait) {
        max-width: none;
        max-height: none;
        height: 100vh;
      }
    }
  }
  &-content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 2.4rem;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100% - 2.4rem);
    @include breakpoint(tablet-portrait) {
      padding: 4rem;
      max-height: calc(100% - 4rem);
    }
    p, li {
      @extend %font-secondary-m;
    }
    footer {
      padding-top: 1.6rem;
      display: flex;
      flex-direction: row;
      gap: 0.8rem;
    }
    .cta {
      &--primary {
        @extend %cta-base;
      }
      &--secondary {
        @extend %cta-inverted;
      }
    }
  }
  &-header {
    padding: 2.4rem 2.4rem 0;
    border-bottom: var(--color-border-light)
  }
  &-title {
    @extend %font-primary-l;
    margin: 0;
  }
  &-close {
    @extend %cta-reset;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.8rem;
    background-color: var(--color-background);
    z-index: 2;
    &:after {
      content: '';
      @include icon(close);
    }
  }
}

html.modal-showing {
  overflow: hidden;
}