@use '~swiper/css/bundle';
@use '../helpers' as *;

// arrows
.swiper-button-next,
.swiper-button-prev {
    position: absolute;
    top: 50%;
    width: auto;
    height: auto;
    &::after {
        content: '';
        @include icon('chevron');
    }
    &:hover {
        background: var(--color-background);
    }
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
    right: 1rem;
    left: auto;
}
.swiper-button-prev, 
.swiper-rtl .swiper-button-next {
    left: 1rem;
    right: auto;
    &::after {
        transform: rotate(180deg);
        transform-origin: center;
    }
}

// pagination
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0;
  display: flex;
  align-items: baseline;
  justify-content: center;
  .swiper-pagination-bullet {
    position: relative;  
    margin: 0;
    opacity: 1;
    @extend %cta-reset;
    width: auto;
    height: var(--button-min-width);
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    align-items: flex-end;
    justify-content: center;
    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 0.2rem;
        transition: all 150ms ease-in-out;
        background: var(--color-border-light);
    }
    &:hover {
        &::before {        
            height: 0.4rem;
        }
    }
    &-active {
        &::before {
            background: var(--color-text);
        }
    }
  }
}