@use '../helpers' as *;

html:not(.is-admin) {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    font-size: var(--root-fontsize);
    scroll-behavior: smooth; /* Supported by firefox and Chrome only. Use a fallback for unsupported browsers, if you need. */
    overflow-x: hidden;
    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }
}

body:not(.wp-admin) {
    font-family: $font-primary;
    color: var(--color-text);
    line-height: var(--root-lineheight);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    margin: 0;
}

figure {
    margin: 0;
    img {
        width: 100%;
        height: auto;
        display: block;
    }
}

ul,
ol {
    margin: 0;
    list-style-position: inside;
    padding-inline-start: 0;
}

hr {
    border-width: 1px 0 0 0;
    margin: 3.2rem 0;
    width: 100%;
}

pre {
  white-space: inherit;
  font-size: 1.2rem;
  .page-body & {
    line-height: 1.4;
    white-space: pre;
  }
}

a {
    color: inherit;
}

fieldset {
    border: 0 solid;
    padding: 0;
 }

// TODO da capire come sistemare: ora va in focus accessibile anche quando scrivo nei campi: brutto...
@each $item in $focusable-elements {
    [data-whatinput='keyboard'] #{$item}:focus {
        @include focus();
    }
}

:focus-visible {
    outline: none;
}