@use 'sass:math';
@use 'sizes' as *;
@use 'colors' as *;
@use 'typography' as *;
@use 'motion' as *;


%cta-reset {
    margin: 0;
    overflow: visible;
    padding: 0;
    background: transparent;
    color: inherit;
    border: 0 none;
    border-radius: 0;
    text-decoration: none;
    cursor: pointer;
}

%cta-base {
    @extend %cta-reset;
    @extend %font-primary-s;
    position: relative;
    cursor: pointer;
    line-height: 1;
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    align-items: center;
    justify-content: center;
    width: fit-content;
    min-height: var(--button-height);
    padding: 0.6rem 2rem;
    border-radius: var(--button-height);
    color: var(--color-background);
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    &:hover {
        color: var(--color-background-primary);
    }
}

%cta-inverted {
    @extend %cta-base;
    color: var(--color-primary);
    background-color: transparent;
    border: 1px solid var(--color-primary);
    &:hover {
        color: var(--color-background);
        background-color: var(--color-primary);
        border: 1px solid var(--color-primary);
    }
}

%cta-inline {
    @extend %font-primary-s;
    color: var(--color-primary);
    display: flex;
    text-decoration: underline;
    text-underline-offset: 0.5rem;
    padding: 0;
    background: none;
    border: 0 solid;
    border-radius: 0;
    min-height: auto;
    &:hover {    
        text-underline-offset: 0.3rem;
    }
}