@use '../helpers' as *;

.header {
	position: sticky;
	top: 0;
	background-color: var(--color-background);
	z-index: zindex(header);
	transition: all 300ms ease-in;
	/* [data-scrolldirection="down"] & {
		top: calc(-1 * var(--header-height))
	} */
	&-inner {
		min-height: var(--header-height);
		@include container($bp-desktop, flex);
		@extend %basiccontent;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
	}
	&-logo {
		transition: all 300ms ease-in-out;
		body.hero-in-viewport & {
			top: -100%;
			opacity: 0;
			pointer-events: none;
		}
		img {
			height: calc(var(--header-height) * 5/7);
			width: auto;
			@include breakpoint(tablet-landscape) {
				height: calc(var(--header-height) * 2/3);
			}
		}
	}
	&-motto {
		display: none;
		@extend %font-primary-m;
		color: var(--color-text);
		position: absolute;
		top: 0;
		left: 2.4rem;
		height: 100%;
		background-color: var(--color-background);
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		transition: all 200ms ease-in-out;
		opacity: 1;
		@include breakpoint(tablet-landscape) {
			display: flex;
		}
		body:not(.hero-in-viewport) & {
			top: -10%;
			opacity: 0;
			pointer-events: none;
		}
	}
}