@use '../helpers' as *;

.wp-block-quote {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 3.2rem;
	@extend %font-secondary-l;
	color: var(--color-primary);
	margin: 0;
	max-width: var(--reading-width);
	&.is-style-full-width {
		max-width: $bp-desktop;
		.single-post & {
			grid-column-start: 1;
			grid-column-end: -1;
		}
	}
	&::before,
	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 1px;
		background-color: currentColor;
	}
	p {
		font-size: inherit;
		line-height: inherit;
		margin: 0;
	}
	cite {
		@extend %font-primary-m;
		font-style: normal;
	}
}