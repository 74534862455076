@mixin icon($icon, $color: null, $size: var(--button-min-width)) {
	display: inline-block;
	width: $size;
	height: $size;
	background-image: var(--sprite-#{$icon});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	@if $color {
		background-color: $color;
		-webkit-mask-image: var(--sprite-#{$icon});
		mask-image: var(--sprite-#{$icon});
		mask-image-repeat: no-repeat;
		background-image: none;
	}
}