@use '../helpers' as *;

.card.card--mission {
	display: grid;
	grid-template-columns: auto;
	gap: 1.6rem;
	@include breakpoint(tablet-landscape) {
		gap: 2.4rem;
		grid-template-columns: 1fr 3fr;
	}
	& + .card {
		border-top: 1px solid var(--color-border-light);
		padding-top: 3.2rem;
	}
	.card {
		&-figure {
			@include breakpoint (tablet-landscape) {
				padding-top: 7.2rem;
			}
			img {
				position: relative;
				z-index: 1;
				width: 100%;
				height: auto;
				object-fit: cover;
				aspect-ratio: 4/3;
				border-radius: var(--border-radius-s);
			}
		}
		&-content {
			display: flex;
			flex-direction: column;
			gap: 1.6rem;
			@include breakpoint (tablet-landscape) {
				display: grid;
				column-gap: 2.4rem;
				row-gap: 0.5rem;
				grid-template-columns: 2fr 1fr;
				grid-template-rows: repeat(3, min-content);
				grid-template-areas: "meta meta" "title title" "copy info";
			}
		}
		&-meta {
			@extend %font-primary-2xs-uppercase;
			@include breakpoint (tablet-landscape) {
				grid-area: meta;
			}
		}
		&-title {
			@extend %font-primary-xl;
			@include breakpoint (tablet-landscape) {
				grid-area: title;
			}
		}
		&-excerpt {
			@extend %font-primary-s;
			@include breakpoint (tablet-landscape) {
				grid-area: copy;
			}
		}
		&-info {
			display: flex;
			flex-direction: column;
			@extend %font-primary-2xs;
			line-height: 2;
			@include breakpoint (tablet-landscape) {
				grid-area: info;
			}
			a {
				color: var(--color-primary);
			}
		}
	}
}