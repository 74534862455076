@use '../helpers' as *;

html.is-admin { // is-admin class added by custom js
    font-size: 16px;
    &.interface-interface-skeleton__html-container {
        font-size: var(--root-fontsize);
    }
}
body.wp-admin:not(.block-editor-page) {    
    font-family: 'Helvetica', 'Arial', sans-serif;
    p {
        font-size: 13px;
        line-height: 1.5;
        margin: 1em 0;
    }
    a {
        color: #3582c4;
    }
    code, pre {
        display: inline-block;
        margin: 0 1px;
        font-size: 12px;
        padding: 3px;
        background-color: rgba(0,0,0,.07);
    }
    h2, h3 {
        font-size: 1.3em;
        margin: 1em 0;
    }
}

body.block-editor-page {
    p {
        margin: 0;
    }
}

.editor-styles-wrapper.block-editor-writing-flow {
	@include container($bp-desktop, flex);
	max-width: var(--reading-width);
	flex-direction: column;
	@extend %basiccontent;
	flex-direction: column;
	h1 {
		@extend %font-primary-3xl;
	}
	.wp-block-post-content {
		display: flex;
		flex-direction: column;
		gap: 3.2rem;
	}
}
