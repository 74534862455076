@use '../helpers' as *;

.taglist {
	list-style: none;
	display: inline-block;
	li {
		display: inline;
	}
	li + li {
		&::before {
			content: ',';
			display: inline-block;
			padding-right: 1ch;
		}
	}
}